<template>
  <div id="component">
    <div class="filter-panel">
      <input
          v-model="keyword"
          class="cs-input"
          placeholder="搜索企业/对接人"
          style="margin-left: 0"
          type="text"
      />
      <button
          class="btn btn-primary btn-sm"
          type="button"
          @click="queryEnterpriseList()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="60">
        <template v-slot:header>
          <div class="table-header-panel" style="text-align: right">
            <button
                class="btn btn-primary sticky-right"
                @click="addEnterpriseInfo"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              企业
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th class="text-center">添加时间</th>
            <th class="text-center">企业名称</th>
            <th class="text-center">统一社会信用代码</th>
            <th class="text-center">营业执照</th>
            <th class="text-center">对接人</th>
            <th class="text-center">备注</th>
            <th class="text-center">操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="enterprise in EnterpriseList" :key="enterprise.id">
            <td class="text-center" style="min-width: 125px">
              {{ enterprise.createTime }}
            </td>
            <td class="text-center">
              <span
                  v-if="enterprise.name"
                  class="allow-click"
                  @click="checkName(enterprise)"
              >
                {{ enterprise.name }}
              </span>
              <template v-else> -</template>
            </td>
            <td class="text-center">
              {{ enterprise.code || "-" }}
            </td>
            <td class="text-center">
              <span
                  v-if="enterprise.licese"
                  class="allow-click"
                  @click="checkLicese(enterprise)"
              >
                查看
              </span>
            </td>
            <td class="text-center">
              {{ enterprise.deputyName || "未知" }} -
              {{
                enterprise.deputyGender === 1
                    ? "男"
                    : enterprise.deputyGender === 2
                        ? "女"
                        : "未知"
              }}
              -
              {{ enterprise.deputyPhone }}
            </td>
            <td>
              <span
                  v-if="enterprise.remark"
                  class="allow-click"
                  @click="checkRemark(enterprise)"
              >
                查看
              </span>
              <template v-else> -</template>
            </td>

            <td class="text-center">
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>

                <ul class="dropdown-menu">
                  <li>
                    <a
                        style="width: 100%; text-decoration: none"
                        @click="modifyEnterprise(enterprise)"
                    >
                      修改对接人
                    </a>
                  </li>
                  <li>
                    <a
                        style="width: 100%; text-decoration: none"
                        @click="openEditEnterprise(enterprise)"
                    >
                      修改企业
                    </a>
                  </li>
                  <li>
                    <a
                        style="width: 100%; text-decoration: none"
                        @click="deleteEnterprise(enterprise.id)"
                    >
                      删除企业
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination/>
    </div>
    <!--查看个人信息-->
    <CSDialog
        :dialogShowConfirmBtn="false"
        :dialogVisible="enterpriseInfoFlag"
        dialog-header-class="alert-bg"
        dialog-title="查看企业信息"
        dialog-visible
        dialog-width="820px"
        dialogCancelBtnText="关闭"
        @onClose="enterpriseInfoFlag = false"
    >
      <template v-slot:dialog-content>
        <div class="dialog-content">
          <div class="dialog-form">
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">企业名称</div>
              <div>{{ enterpriseInfo.name }}</div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">法人</div>
              <div>
                {{ enterpriseInfo.legalPerson }}
              </div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">营业执照</div>
              <div>
                <span
                    style="
                    display: inline-block;
                    margin: 0 auto;
                    text-align: left;
                  "
                >
                  <img
                      :src="enterpriseInfo.licese"
                      alt=""
                      style="width: 200px"
                      @click="checkLicese(enterpriseInfo)"
                  />
                </span>
              </div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">统一社会信用代码</div>
              <div>
                {{ enterpriseInfo.code }}
              </div>
            </div>
            <div class="dialog-form-field" style="margin-bottom: 10px">
              <div class="field-name">对公账号</div>
              <div>
                {{ enterpriseInfo.account }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!--添加企业-->
    <CSDialog
        :dialog-visible="addEnterpriseFlag"
        :dialogTitle="`添加企业`"
        dialog-width="1080px"
        @onClose="addEnterpriseFlag = false"
        @onConfirm="addEnterprise"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form" style="padding:30px 0" @click="personalNameSearchList = []">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">企业名称</div>
            <div>
              <input
                  v-model="enterprise.name"
                  placeholder="限30个字"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
                  @click="searchName"
                  @input="searchName"
              />
              <ul v-if="personalNameSearchList.length>0"
                  style="list-style:none;
                            position: absolute;z-index: 9999;
                            background-color: white;
                            border: 1px solid #979797;
                            width: 347px;
                            padding:5px 0 5px 10px;
                            border-radius: 4px;
                            font-size: 14px;
                            ">
                <li v-for="item in personalNameSearchList" :key="item.id">{{ item.name }}</li>
              </ul>
            </div>
          </div>
          <div style="margin-left: 246px;margin-top: -18px;margin-bottom:10px">
            <svg
                aria-hidden="true"
                class="icon"
                style="font-size: 20px; margin-right: 10px"
            >
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>

            <span style="font-size: 20px;color: #999999"
            >输入3个字后如有相同关键字的企业将会提示。</span
            >
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">法人</div>
            <div>
              <input
                  v-model="enterprise.legalPerson"
                  placeholder="限10个字"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="margin-bottom: 46px;line-height: 21px;">营业执照</div>
            <div v-if="enterprise.licese !== ''" style="position: relative">
              <img
                  :src="enterprise.licese"
                  alt=""
                  style="width: 100px; height: 100px"
                  @click="uploadAddEnterprisePhoto"
              />
              <img
                  src="../../assets/icon4.png"
                  style="
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: -5px;
                  right: -5px;
                "
                  @click="deleteFacePhoto()"
              />
            </div>
            <div v-else>
              <div class="upload-photo" @click="uploadAddEnterprisePhoto">
                <div class="text-center">
                  <img
                      alt=""
                      src="../../assets/upload.png"
                      style="margin: 5px auto"
                  />
                  <p style="color: #999">上传照片</p>
                </div>
              </div>
            </div>
            <input
                id="uploadAddEnterprisePhotoId"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="showImage($event)"
            />
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">统一社会信用代码</div>
            <div>
              <input
                  v-model="enterprise.code"
                  placeholder="限18位"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">对公账号</div>
            <div>
              <input
                  v-model="enterprise.account"
                  placeholder="请输入"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="vertical-align: top">
              对接人
            </div>
            <div style="width: 347px">
              <ChooseLocation
                  :showInput="false"
                  mission="deputy"
                  style="--readonly-input-width: 307px"
                  @changeLocation="changeLocation"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="margin-bottom:170px;line-height: 21px;">备注</div>
            <div class="field">
              <textarea
                  v-model="enterprise.remark"
                  maxlength="200"
                  placeholder="限200字，非必填"
                  style="
                  width: 720px;
                  height: 200px;
                  padding: 0 15px;
                  resize: none;
                  border: 0px;
                  border-radius: 10px;
                  background-color: #f0f0f0;
                "
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

    <!--修改企业-->
    <CSDialog
        :dialog-visible="editEnterpriseFlag"
        :dialogTitle="`修改企业`"
        dialog-width="1080px"
        @onClose="editEnterpriseFlag = false"
        @onConfirm="editEnterprise"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">企业名称</div>
            <div>
              <input
                  v-model="editEnterpriseInfo.name"
                  disabled="false"
                  placeholder="限30个字"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">法人</div>
            <div>
              <input
                  v-model="editEnterpriseInfo.legalPerson"
                  placeholder="限10个字"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="margin-bottom: 62px;line-height: 21px;">营业执照</div>
            <div
                v-if="editEnterpriseInfo.licese !== ''"
                style="position: relative"
            >
              <img
                  :src="editEnterpriseInfo.licese"
                  alt=""
                  style="width: 100px; height: 100px"
                  @click="uploadEditEnterprisePhoto"
              />
              <img
                  src="../../assets/icon4.png"
                  style="
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: -5px;
                  right: -5px;
                "
                  @click="deleteFacePhoto2()"
              />
            </div>
            <div v-else>
              <div class="upload-photo" @click="uploadEditEnterprisePhoto">
                <div class="text-center">
                  <img
                      alt=""
                      src="../../assets/upload.png"
                      style="margin: 5px auto"
                  />
                  <p style="color: #999">上传照片</p>
                </div>
              </div>
            </div>
            <input
                id="uploadEditEnterprisePhotoId"
                accept="images/*"
                class="file"
                hidden
                type="file"
                @change="showImage2($event)"
            />
          </div>

          <div class="dialog-form-item">
            <div class="dialog-form-item-label">统一社会信用代码</div>
            <div>
              <input
                  v-model="editEnterpriseInfo.code"
                  disabled="false"
                  placeholder="限18位"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">对公账号</div>
            <div>
              <input
                  v-model="editEnterpriseInfo.account"
                  placeholder="请输入"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="vertical-align: top">
              对接人
            </div>
            <div style="width: 347px">
              <ChooseLocation
                  :default-location-name="editEnterpriseInfo.location"
                  :showInput="false"
                  mission="deputy"
                  style="--readonly-input-width: 307px"
                  @changeLocation="changeLocation2"
              />
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label" style="margin-bottom:170px;line-height: 21px;">备注</div>
            <div class="field">
              <textarea
                  v-model="editEnterpriseInfo.remark"
                  maxlength="200"
                  placeholder="限200字，非必填"
                  style="
                  width: 720px;
                  height: 200px;
                  padding: 0 15px;
                  resize: none;
                  border: 0px;
                  border-radius: 10px;
                  background-color: #f0f0f0;
                "
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!--修改对接人-->
    <CSDialog
        :dialog-visible="oppositePerson"
        :dialogTitle="`修改对接人`"
        class="oppositeName"
        dialog-width="470px"
        @onClose="
        oppositePerson = false;
        deputySave = {};
      "
        @onConfirm="editOppositePerson"
    >
      <div slot="dialog-content" class="room-form">
        <div class="field-item">
          <label
              class="field-item-label"
              style="width: 80px; margin-right: 20px"
          >对接人</label
          >
          <div class="field-item-content" style="position: relative">
            <CSSelect height="40px" iWidth="36px" style="width: 305px">
              <DropDownSelection
                  :dataList="modifyEnterpriseList"
                  :item="deputyItem"
                  :placeholderName="'搜索姓名/手机号'"
                  type="deputy"
                  @queryInfo="queryInfo"
                  @selectWarte="selectValue"
              ></DropDownSelection>
            </CSSelect>
          </div>
        </div>
      </div>
    </CSDialog>


    <CSDialog
        :dialog-visible="checkoutImg"
        :dialogShowConfirmBtn="false"
        :dialogTitle="`查看营业执照`"
        class="oppositeName"
        dialog-width="700px"
        dialogCancelBtnText="关闭"
        dialogHeaderClass="alert-bg"
        @onClose="checkoutImg=false"
    >
      <div slot="dialog-content" class="room-form">
        <!--                <img-->
        <!--                        :src="enterpriseItem"-->
        <!--                        @click="checkoutImgBtn"-->
        <!--                        alt=""-->
        <!--                        class="checkoutImgC"-->
        <!--                />-->
        <CSImage :srcImg="enterpriseItem">

        </CSImage>
      </div>

    </CSDialog>

  </div>
</template>


<script>
import {
  addEnterprise,
  editDeputyPhone,
  editEnterprise,
  personalNameSearchUrl,
  queryIndividualList,
  queryUserByPhoneOrName,
  removeIndividual,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import ChooseLocation from "@/components/ChooseLocation";
import Pagination from "@/components/Pagination.vue";
import DropDownSelection from "@/components/DropDownSelection.vue";
import CSSelect from "@/components/common/CSSelect";
import CSImage from "@/components/common/CSImage";

export default {
  name: "Enterprise",
  components: {
    CSTable,
    CSDialog,
    ChooseLocation,
    Pagination,
    DropDownSelection,
    CSSelect,
    CSImage
  },
  data() {
    return {
      enterpriseItem: null,
      checkoutImg: false,
      deputyItem: {}, //本条信息内容,用于回显
      deputySave: {},
      deputyId: "",
      modifyEnterpriseList: [], //企业对接人列表
      oppositePerson: false, //对接人显示框
      keyword: "",
      filterHeight: 0,
      EnterpriseList: [],
      addEnterpriseFlag: false, //添加企业
      enterprise: {
        //添加企业
        id: "",
        name: "", //企业名称 个人名称
        code: "", //统一社会信用代码/身份证号
        legalPerson: "", //法人/身份
        licese: "", //营业执照/身份证 url
        account: "", //对公账号/对私账号
        deputyId: "",
        deputyName: "",
        deputyGender: "",
        deputyPhone: "",
        location: "",
        remark: "",
      },
      enterpriseInfoFlag: false, //查看个人信息
      enterpriseInfo: {}, //查看个人信息
      editEnterpriseFlag: false, //修改企业
      editEnterpriseInfo: {}, //修改企业
      oppositePersonList: [], //对接人列表
      personalNameSearchList: [],
      timer: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.queryEnterpriseList();
    this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
      this.pageParams.pageNo = page;
      this.queryEnterpriseList(page);
    });
    window.addEventListener("keydown", this.queryEnterpriseListDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.queryEnterpriseListDown);
  },
  deactivated() {
    window.removeEventListener('keydown', this.queryEnterpriseListDown);
  },
  methods: {
    queryEnterpriseListDown(e) {
      if (e.keyCode == 13) {
        console.log('enterprise');
        this.queryEnterpriseList();
      }
    },
    //编辑对接人信息
    editOppositePerson() {
      if (JSON.stringify(this.deputySave) !== "{}") {
        let params = {
          id: this.deputyItem.id,
          deputyId: this.deputySave.id,
          deputyName: this.deputySave.userName,
          deputyPhone: this.deputySave.phone,
          deputyGender: this.deputySave.gender,
        };
        this.$fly.post(editDeputyPhone, params).then((res) => {
          if (res.code !== 0) {
            return;
          }
          this.oppositePerson = false;
          this.deputySave = {};
          this.$vc.toast("修改对接人成功");
          this.queryEnterpriseList();
        });
      }
    },
    //选中的对接人
    selectValue(item) {
      this.deputySave = item;
    },
    //查询对接人
    queryInfo(search = "") {
      this.$fly
          .get(queryUserByPhoneOrName, {search, deputyId: this.deputyId})
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.modifyEnterpriseList = res.data;
          });
    },
    //修改企业对接人弹窗
    modifyEnterprise(item) {
      this.deputyId = item.deputyId;
      this.deputyItem = item;
      this.queryInfo();
      this.oppositePerson = true;
    },
    //查询企业列表
    queryEnterpriseList(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const params = {
        regionCode: this.$vc.getCurrentRegion().communityId,
        search: this.keyword,
        pageNo,
        pageSize,
        type: 1,
      };
      this.$fly.post(queryIndividualList, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        if (pageNo == 1) {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            pageSize,
            currentPage: pageNo,
          });
        }
        this.EnterpriseList = res.data.datas;
      });
    },
    //删除企业
    deleteEnterprise(id) {
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: "<span>确认删除吗?</span>",
        onConfirm: () => {
          this.$fly.get(removeIndividual, {id: id}).then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast("删除成功");
            this.queryEnterpriseList();
            this.$CSDialog.instance.closeDialog();
          });
        },
      });
    },
    //查看备注
    checkRemark(enterprise) {
      this.$CSDialog.alert({
        title: "查看备注",
        messageHtml: `<span style="display: inline-block;margin: 0 auto;text-align: left;">${enterprise.remark}</span>`,
        width: "450px",
      });
    },
    //查看图片
    checkLicese(enterprise) {
      this.enterpriseItem = enterprise.licese;
      this.checkoutImg = true;
      // this.$CSDialog.alert({
      //     title: "查看营业执照",
      //     // messageHtml: `<span style="display: inline-block;margin: 0 auto;text-align: left; image-rendering:-moz-crisp-edges;
      //     //                           image-rendering:-o-crisp-edges;
      //     //                           image-rendering:-webkit-optimize-contrast;
      //     //                           image-rendering:crisp-edges;
      //     //                           -ms-interpolation-mode:nearest-neighbor">
      //     //                          <img
      //     //                          src="${enterprise.licese}"
      //     //                          onclick="this.checkoutImg"
      //     //                          alt=""
      //     //                          style="height:400px"
      //     //                         />
      //     //                 </span>`,
      //     // width: "750px",
      // });
    },
    checkoutImgBtn() {
      console.log(1111111);
    },
    //查看个人信息
    checkName(enterprise) {
      this.enterpriseInfo = enterprise;
      this.enterpriseInfoFlag = true;
    },
    //异步上传照片
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
            "uploadImg",
            "upload",
            {
              img: base64,
            },
            {
              headres: {
                "Content-Type": "application/json",
              },
            },
            (resStr, res) => {
              reslove(res.body.fileSaveName);
            },
            (errText, err) => {
              reject(errText);
            }
        );
      });
    },

    //添加企业->删除照片
    deleteFacePhoto() {
      this.enterprise.licese = "";
    },
    //添加企业->回显照片
    async showImage(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        var file = photoFiles[0];
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2M!");
          return false;
        }
      }


      let formData = new FormData(); //新建FormData对象
      formData.append("files", photoFiles[0]);
      formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
      formData.append("compress", "false");
      formData.append('fileName', '营业执照')
      const photo = await this.Constants.uploadFileOne(formData);
      if (photo) {
        this.enterprise.licese = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
      }


      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onloadend = async () => {
      //     let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
      //         this.$vc.toast("上传图片错误,请重新上传");
      //     });
      //     if (imgSrc && imgSrc != "") {
      //         this.enterprise.licese = imgSrc;
      //     }
      //     event.target.value = "";
      // };
    },
    //添加上传照片
    uploadAddEnterprisePhoto() {
      $("#uploadAddEnterprisePhotoId").trigger("click");
    },
    //打开添加企业
    addEnterpriseInfo() {
      this.enterprise.licese = "";
      this.addEnterpriseFlag = true;
    },
    //添加企业
    addEnterprise() {
      let that = this;
      const params = {
        regionCode: this.$vc.getCurrentRegion().communityId,
        name: this.enterprise.name,
        code: this.enterprise.code,
        legalPerson: this.enterprise.legalPerson,
        licese: this.enterprise.licese,
        account: this.enterprise.account,
        deputyId: this.enterprise.deputyId,
        deputyName: this.enterprise.deputyName,
        deputyGender: this.enterprise.deputyGender,
        deputyPhone: this.enterprise.deputyPhone,
        remark: this.enterprise.remark,
      };
      this.$fly.post(addEnterprise, params).then((res) => {
        if (res.code !== 0) {
          that.$vc.toast(res.msg);
          return;
        }
        this.enterprise = {};
        this.addEnterpriseFlag = false;
        Object.keys(this.enterprise).forEach(
            (key) => (this.enterprise[key] = "")
        );
        this.$vc.toast("添加企业成功");
        this.queryEnterpriseList();
      });
    },
    //添加企业 对接人
    changeLocation(location) {
      console.log(location);
      console.log(11);
      this.enterprise.deputyId = location.id;
      let deputyName = `${location.name}`;
      let deputyGender = `${location.gender}`;
      let deputyPhone = `${location.phone}`;
      let locationInfo = "";
      if (
          deputyName !== "undefined" &&
          deputyName !== undefined &&
          deputyName !== "null"
      ) {
        this.enterprise.deputyName = `${location.name}`;
        locationInfo += deputyName + "-";
        console.log("!===null");
      } else {
        this.enterprise.deputyName = "未知";
        locationInfo += deputyName + "-";
      }
      if (
          deputyGender !== "undefined" &&
          deputyGender !== undefined &&
          deputyGender !== "null"
      ) {
        this.enterprise.deputyGender = `${location.gender}`;
        locationInfo +=
            deputyGender === "1" ? "男-" : deputyGender === "2" ? "女-" : "未知-";
      }
      if (
          deputyPhone !== "undefined" &&
          deputyPhone !== undefined &&
          deputyPhone !== "null"
      ) {
        this.enterprise.deputyPhone = `${location.phone}`;
        locationInfo += deputyPhone;
      }
      this.enterprise.location = locationInfo;
    },

    //修改企业->删除照片
    deleteFacePhoto2() {
      this.editEnterpriseInfo.licese = "";
    },
    //修改企业->回显照片
    async showImage2(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        var file = photoFiles[0];
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2M!");
          return false;
        }
      }


      let formData = new FormData(); //新建FormData对象
      formData.append("files", photoFiles[0]);
      formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
      formData.append("compress", "false");
      formData.append('fileName', '营业执照')
      const photo = await this.Constants.uploadFileOne(formData);
      if (photo) {
        this.editEnterpriseInfo.licese = this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
      }


      // var reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onloadend = async () => {
      //     let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
      //         this.$vc.toast("上传图片错误,请重新上传");
      //     });
      //     if (imgSrc && imgSrc != "") {
      //         this.editEnterpriseInfo.licese = imgSrc;
      //     }
      //     event.target.value = "";
      // };
    },
    //修改上传照片
    uploadEditEnterprisePhoto() {
      $("#uploadEditEnterprisePhotoId").trigger("click");
    },
    //打开修改企业信息
    openEditEnterprise(enterprise) {
      this.editEnterpriseInfo = {...enterprise};
      let location = {
        id: enterprise.deputyId,
        userName: enterprise.deputyName,
        gender: enterprise.deputyGender,
        phone: enterprise.deputyPhone,
      };
      this.changeLocation2(location);
      this.editEnterpriseFlag = true;
    },
    //修改企业信息
    editEnterprise() {
      let params = {
        id: this.editEnterpriseInfo.id,
        legalPerson: this.editEnterpriseInfo.legalPerson,
        licese: this.editEnterpriseInfo.licese,
        account: this.editEnterpriseInfo.account,
        deputyId: this.editEnterpriseInfo.deputyId,
        deputyName: this.editEnterpriseInfo.deputyName,
        deputyGender: this.editEnterpriseInfo.deputyGender,
        deputyPhone: this.editEnterpriseInfo.deputyPhone,
        remark: this.editEnterpriseInfo.remark,
      };
      this.$fly.post(editEnterprise, params).then((res) => {
        if (res.code !== 0) {
          return;
        }
        this.editEnterpriseFlag = false;
        this.$vc.toast("修改企业成功");
        this.queryEnterpriseList();
      });
    },
    //修改企业 对接人
    changeLocation2(location) {
      console.log(1111);
      this.editEnterpriseInfo.deputyId = location.id;
      let deputyName = `${location.name}`;
      let deputyGender = `${location.gender}`;
      let deputyPhone = `${location.phone}`;
      let locationInfo = "";
      if (
          deputyName !== "undefined" &&
          deputyName !== undefined &&
          deputyName !== "null"
      ) {
        this.editEnterpriseInfo.deputyName = `${location.name}`;
        locationInfo += deputyName + "-";
      } else {
        this.editEnterpriseInfo.deputyName = "未知";
        locationInfo += deputyName + "-";
      }
      if (
          deputyGender !== "undefined" &&
          deputyGender !== undefined &&
          deputyGender !== "null"
      ) {
        this.editEnterpriseInfo.deputyGender = `${location.gender}`;
        locationInfo +=
            deputyGender === "1" ? "男-" : deputyGender === "2" ? "女-" : "未知-";
      }
      if (
          deputyPhone !== "undefined" &&
          deputyPhone !== undefined &&
          deputyPhone !== "null"
      ) {
        this.editEnterpriseInfo.deputyPhone = `${location.phone}`;
        locationInfo += deputyPhone;
      }
      this.editEnterpriseInfo.location = locationInfo;
    },
    async searchName() {
      if (this.enterprise.name.length > 2) {
        clearTimeout(
            this.timer
        )
        this.timer = setTimeout(async () => {
          let res = await this.$fly.get(personalNameSearchUrl, {search: this.enterprise.name, type: 1});
          if (res.code !== 0) {
            return;
          }
          this.personalNameSearchList = res.data;
        }, 200)

      } else {
        this.personalNameSearchList = [];
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-form {
  padding: 0px 30px;
  font-size: 24px;
  color: #000;

  &-item {
    &:not(:last-of-type) {
      margin-bottom: 23px;
    }

    &-label {
      width: 206px;
      height: 33px;
      text-align: right;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 33px;
    }

    & > div {
      display: inline-block;
      vertical-align: middle;

      input {
        &::placeholder {
          color: #999;
        }

        width: 140px;
        height: 40px;
        border-radius: 4px;
        padding: 0 5px;
        border: 1px solid #979797;
        vertical-align: middle;
        padding-left: 10px;
      }
    }
  }
}

.dialog-content {
  padding: 30px;

  .dialog-form {
    &-field {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 24px;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      .field-name {
        width: 240px;
        margin-right: 40px;
        text-align: right;
        flex: 0 0 240px;
      }

      input {
        border: 1px solid #979797;
        border-radius: 4px;
      }

      .show-rule {
        display: flex;
        flex-flow: row nowrap;

        // align-items center

        &-name {
          width: 120px;
          flex: 0 0 120px;
          margin-right: 40px;
        }
      }
    }
  }

  .tips {
    display: flex;
    font-size: 20px;
    color: #999;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 16px;

    .icon {
      margin-top: 4px;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
      line-height: 1.2;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
    }
  }
}

.room-form {
  padding: 27px 30px;

  .field-item {
    margin-bottom: 22px;
    font-size: 0;

    &-label {
      font-size: 24px;
      text-align: right;
      width: 208px;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-bottom: 0;
    }

    &-content {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;

      input {
        width: 400px;
        height: 40px;
        text-indent: 5px;
        border-radius: 4px;
        border: 1px solid #979797;

        &::placeholder {
          color: #999;
        }
      }

      select {
        width: 220px;
        text-indent: 5px;
      }
    }
  }

  .tips {
    color: #999;
    font-size: 20px;
  }

  .checkoutImgC {
    width: 640px;
    height: auto;
    margin: 0 auto;
    display: inline-block

  }
}
</style>
